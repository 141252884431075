import { AppCommonModule } from './modules/common/common.module';
import { DirectivesModule } from './common/directives/directives.module';
import { PipesModule } from './common/pipes/pipes.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GlobalService } from './common/global/app.global.service';
import {ShareService} from './common/services/share.service'
import { AutoLogoutService } from './common/services/AutoLogoutService';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './modules/secure/home';
import { AppComponent } from './app.component';
import { AuthGuard } from './common/helpers/auth.guard';
import { HttpInterceptors } from './common/helpers/http.interceptors';
import { ErrorInterceptor } from './common/helpers/error.interceptor';
import { ChangepasswordComponent } from './modules/secure/changepassword/changepassword.component';
import { CreateComponent } from './modules/secure/home/create/create.component';
import { GoalsComponent } from './modules/secure/goals/goals.component';
import { TeamsComponent } from './modules/secure/teams/teams.component';
import { IndividualComponent } from './modules/secure/individual/individual.component';
import { IndividualCreateComponent } from './modules/secure/individual/individual-create/individual-create.component';
import { TeamCreateComponent } from './modules/secure/teams/team-create/team-create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { GoalsCreateComponent } from './modules/secure/goals/goals-create/goals-create.component';
import { DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorPickerModule} from 'ngx-color-picker';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';
import { Angulartics2Module } from 'angulartics2';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MatTooltipModule } from '@angular/material/tooltip';

import { RootStoreModule } from './store/root-store.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MilestoneCreateComponent } from './modules/secure/goals/milestone-create/milestone-create.component';
import { PrivacyConsentComponent } from './modules/modals/privacy-consent/privacy-consent.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GooglePlacesAutocompleteDirective } from '@common/directives/google-places-autocomplete.directive';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ChangepasswordComponent,
        CreateComponent,
        GoalsComponent,
        TeamsComponent,
        IndividualComponent,
        IndividualCreateComponent,
        TeamCreateComponent,
        GoalsCreateComponent,
        MilestoneCreateComponent,
        PrivacyConsentComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        RootStoreModule,
        NgApexchartsModule,
        PipesModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        PaginationModule.forRoot(),
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        ColorPickerModule,
        CommonModule,
        AppCommonModule,
        DragDropModule,
        NgSelectModule,
        MatTabsModule,
        MatSelectModule,
        MatMenuModule,
        MatAutocompleteModule,
        NgxPaginationModule,
        GooglePlacesAutocompleteDirective,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTooltipModule,
        DirectivesModule,
        MatProgressBarModule,
        Angulartics2Module.forRoot({
            gst: {
                trackingIds: ['G-QEQ9QYZJ6D'],
            }
        }),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptors, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        GlobalService,
        DatePipe,
        ShareService,
        AutoLogoutService,
        provideHttpClient(withInterceptorsFromDi(), withFetch())
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
